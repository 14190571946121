<template>
    <div class="index">
        <!-- 搜索 -->
        <div class="sou">
            <el-select v-model="value" placeholder="请选择页面位置">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div class="xian">——</div>
            <el-select v-model="value" placeholder="素材配音">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div class="xian">——</div>
            <el-select v-model="value" placeholder="请选择配音师">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div class="xian">——</div>
            <el-select v-model="value" placeholder="视频名称">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-button type="primary" icon="el-icon-search" width="95">搜索</el-button>
        </div>
        <!--功能-->
        <div class="fun">
            <el-button type="primary" icon="el-icon-search" class="primary">添加视频</el-button>
            <el-button type="success" icon="el-icon-search" class="success">批量处理</el-button>
        </div>
        <!--表格 -->
        <div class="table">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="date" label="id"></el-table-column>
                <el-table-column prop="name" label="视频名称"></el-table-column>
                <el-table-column prop="address" label="视频类型"></el-table-column>
                <el-table-column prop="name" label="所属页面"> </el-table-column>
                <el-table-column prop="name" label="所属配音师"></el-table-column>
                <el-table-column prop="name" label="页面位置"> </el-table-column>
                <el-table-column prop="name" label="视频文件"> </el-table-column>
                <el-table-column prop="name" label="视频封面"> </el-table-column>
                <el-table-column prop="name" label="音频文件"> </el-table-column>
                <el-table-column prop="name" label="发布时间"></el-table-column>
                <el-table-column prop="name" label="更新时间"></el-table-column>
                <el-table-column prop="name" label="发布状态"></el-table-column>
                <el-table-column prop="name" label="操作"></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 下拉框数据
            options: [
                {
                    value: "选项1",
                    label: "黄金糕",
                },
                {
                    value: "选项2",
                    label: "双皮奶",
                },
                {
                    value: "选项3",
                    label: "蚵仔煎",
                },
                {
                    value: "选项4",
                    label: "龙须面",
                },
                {
                    value: "选项5",
                    label: "北京烤鸭",
                },
            ],
            value: "",
            tableData: [
                {
                    //表格数据
                    date: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-04",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1517 弄",
                },
                {
                    date: "2016-05-01",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1519 弄",
                },
                {
                    date: "2016-05-03",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1516 弄",
                },
            ],
        };
    },

    mounted() {
        // this.onceshi();
    },

    methods: {
        // 测试用
        onceshi() {
            // console.log(123);
            var prame = {};
            this.$service.get(this.$api.getWxpublicArt, prame, (res) => {
                if (res.code == "200") {
                }
            });
        },
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);s
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
    },
};
</script>

<style lang="scss" scoped>
.index {
    display: flex;
    flex-direction: column; //垂直显示
    .center {
        background: #fff;
        width: 98%;
        margin: 0 auto;
        margin-top: 20px;
    }
    // .button {
    //     width: 95px;
    //     height: 33px;
    //     background: linear-gradient(-19deg, #479eff, #6d93ff);
    //     box-shadow: 2px 3px 4px 0px rgba(0, 37, 77, 0.23);
    //     border-radius: 5px;
    //     color: #fff;
    // }
    .sou {
        display: flex;
        margin: 0 auto;
        margin-top: 40px;
    }
    .xian {
        width: 29px;
        height: 2px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #6d93ff;
        line-height: 33px;
        margin-left: 10px;
        margin-right: 10px;
    }
    input {
        margin-top: 40px;
        color: #85bdfa;
    }
    .sousuo {
        margin-right: 5px;
    }
    .button {
        margin-left: 20px;
    }
    .fun {
        display: flex;
        margin-left: 40px;
        margin-top: 40px;
    }
    .table {
        margin-left: 41px;
        margin-right: 55px;
        margin-top: 25px;
    }
}
</style>
<style lang="scss">
.el-table .cell {
    text-align: center !important;
}
</style>
